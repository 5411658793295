'use client';

import React, { useState, useCallback, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Paper, Typography, TextField, IconButton, Box, CircularProgress, Tooltip, Button, Dialog, DialogContent, DialogActions, Divider } from '@mui/material';
import { Plus, ChevronDown, ChevronUp, Trash2, Send, Printer } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';
import OpenAI from 'openai';
import logo777 from '../../assets/images/777.png';
import { logger } from 'utils/logger';

// Initialize OpenAI
const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true, // Use a proxy server for production
});

// Recommendation type definition
interface Recommendation {
  id: string;
  recommendation: string;
  benefits: string;
  isLoadingBenefits?: boolean;
  isValid?: boolean;
}

// Add this interface at the top with other interfaces
interface RecommendationWithUI extends Recommendation {
  isExpanded: boolean;
}

// Helper function to generate benefits
const generateBenefits = async (recommendation: string): Promise<string> => {
  try {
    const prompt = `Given the pharmacist's recommendation "${recommendation}", provide exactly 4 brief, bullet-point benefits. Keep each benefit concise and focused, like a PowerPoint presentation bullet point. Format with • at the start of each line.`;

    const completion = await openai.chat.completions.create({
      messages: [{ role: 'user', content: prompt }],
      model: 'gpt-4',
      temperature: 0.7,
      max_tokens: 200,
    });

    return completion.choices[0]?.message?.content || 'No benefits generated.';
  } catch (error) {
    console.error('Error generating benefits:', error);
    throw new Error('Failed to generate benefits.');
  }
};

const typewriterEffect = async (text: string, updateCallback: (partialText: string) => void) => {
  const typingSpeed = 20; // Typing speed in milliseconds
  for (let i = 0; i <= text.length; i++) {
    await new Promise((resolve) => setTimeout(resolve, typingSpeed));
    updateCallback(text.slice(0, i)); // Update the benefits field incrementally
  }
};

// RecommendationCard component
const RecommendationCard = React.memo(
  ({
    rec,
    index,
    updateRecommendation,
    removeRecommendation,
    isLast,
    isExpanded,
    toggleExpanded,
  }: {
    rec: RecommendationWithUI;
    index: number;
    updateRecommendation: (id: string, field: keyof Omit<Recommendation, 'id'>, value: string | boolean) => void;
    removeRecommendation: (id: string) => void;
    isLast: boolean;
    isExpanded: boolean;
    toggleExpanded: (id: string) => void;
  }) => {
    const benefitsRef = useRef<HTMLInputElement | null>(null);

    // Handle key press event
    const handleKeyDown = async (e: React.KeyboardEvent) => {
      if (e.key === 'Enter' && rec.recommendation.trim() !== '') {
        e.preventDefault();
        updateRecommendation(rec.id, 'isLoadingBenefits', true); // Start loading
        try {
          const benefits = await generateBenefits(rec.recommendation);
          // Typewriter effect
          await typewriterEffect(benefits, (partialText) => updateRecommendation(rec.id, 'benefits', partialText));
        } catch (error) {
          updateRecommendation(rec.id, 'benefits', 'Error generating benefits. Please try again.');
        } finally {
          updateRecommendation(rec.id, 'isLoadingBenefits', false); // End loading
        }
        benefitsRef.current?.focus();
      }
    };

    return (
      <motion.div
        layout
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{ duration: 0.3 }}
      >
        <Paper elevation={3} sx={{ mb: 2, overflow: 'hidden' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              p: 2,
              bgcolor: 'primary.main',
              color: 'white',
            }}
          >
            <Typography variant="h6" sx={{ color: 'white' }}>
              Recommendation {index + 1}
            </Typography>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <Tooltip title={isExpanded ? 'Collapse' : 'Expand'}>
                <IconButton
                  size="small"
                  onClick={() => toggleExpanded(rec.id)}
                  sx={{
                    color: 'white',
                    '&:hover': {
                      bgcolor: 'rgba(255, 255, 255, 0.1)',
                    },
                  }}
                >
                  {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
                </IconButton>
              </Tooltip>
              {!isLast && (
                <Tooltip title="Delete">
                  <IconButton
                    size="small"
                    onClick={() => removeRecommendation(rec.id)}
                    sx={{
                      color: 'white',
                      '&:hover': {
                        bgcolor: 'rgba(255, 255, 255, 0.1)',
                      },
                    }}
                  >
                    <Trash2 size={20} />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
          <AnimatePresence>
            {isExpanded && (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: 'auto' }}
                exit={{ height: 0 }}
                transition={{ duration: 0.3 }}
                style={{ overflow: 'hidden' }}
              >
                <Box
                  sx={{
                    p: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 3,
                    bgcolor: 'background.paper',
                  }}
                >
                  <Box>
                    <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
                      Recommendation
                    </Typography>
                    <TextField
                      fullWidth
                      multiline
                      minRows={2}
                      value={rec.recommendation}
                      onChange={(e) => updateRecommendation(rec.id, 'recommendation', e.target.value)}
                      onKeyDown={handleKeyDown}
                      placeholder="Enter your recommendation..."
                      variant="outlined"
                    />
                  </Box>
                  <Box>
                    <Typography variant="subtitle2" sx={{ mb: 1, color: 'text.secondary' }}>
                      Benefits
                    </Typography>
                    <Box sx={{ position: 'relative' }}>
                      <TextField
                        fullWidth
                        multiline
                        minRows={3}
                        value={rec.benefits}
                        onChange={(e) => updateRecommendation(rec.id, 'benefits', e.target.value)}
                        placeholder="Benefits will be generated automatically..."
                        variant="outlined"
                        disabled={rec.isLoadingBenefits}
                        inputRef={benefitsRef}
                      />
                      {rec.isLoadingBenefits && (
                        <CircularProgress
                          size={20}
                          sx={{
                            position: 'absolute',
                            right: 10,
                            top: 10,
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </motion.div>
            )}
          </AnimatePresence>
        </Paper>
      </motion.div>
    );
  }
);

RecommendationCard.displayName = 'RecommendationCard';

// Add this interface with the others
interface PrintPreviewProps {
  open: boolean;
  onClose: () => void;
  recommendations: RecommendationWithUI[];
}

// Update the PrintPreview component
const PrintPreview: React.FC<PrintPreviewProps> = ({ open, onClose, recommendations }) => {
  const today = new Date().toLocaleDateString('en-AU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    if (!printWindow) return;

    printWindow.document.write(`
      <!DOCTYPE html>
      <html>
        <head>
          <title>Clinical Recommendations</title>
          <style>
            body {
              font-family: "Times New Roman", serif;
              padding: 40px;
              max-width: 800px;
              margin: 0 auto;
              line-height: 1.6;
            }
            .date {
              text-align: right;
              margin-bottom: 30px;
            }
            .title {
              text-align: center;
              font-weight: bold;
              text-transform: uppercase;
              letter-spacing: 1px;
              padding-bottom: 10px;
              border-bottom: 1px solid black;
              margin-bottom: 30px;
            }
            .recommendation {
              margin-bottom: 30px;
            }
            .recommendation-title {
              font-weight: bold;
              margin-bottom: 10px;
            }
            .recommendation-text {
              padding-left: 20px;
              text-align: justify;
              margin-bottom: 15px;
            }
            .benefits {
              padding-left: 20px;
            }
            .benefit-item {
              padding-left: 20px;
              position: relative;
              margin-bottom: 5px;
            }
            .benefit-item:before {
              content: "•";
              position: absolute;
              left: 0;
            }
            .footer {
              margin-top: 50px;
              padding-top: 20px;
              border-top: 1px solid black;
              text-align: center;
              font-style: italic;
              font-size: 0.9em;
            }
            @media print {
              body { padding: 0; }
              @page { margin: 20mm; }
            }
          </style>
        </head>
        <body>
          <div class="date">${today}</div>
          <div class="title">Pharmacist Clinical Recommendations</div>
          ${recommendations.map((rec, index) => `
            <div class="recommendation">
              <div class="recommendation-title">${index + 1}. Clinical Recommendation:</div>
              <div class="recommendation-text">${rec.recommendation}</div>
              <div class="recommendation-title">Clinical Benefits:</div>
              <div class="benefits">
                ${rec.benefits.split('\n').map(benefit =>
      `<div class="benefit-item">${benefit.replace('•', '').trim()}</div>`
    ).join('')}
              </div>
            </div>
          `).join('')}
          <div class="footer">
            These clinical recommendations have been prepared by your pharmacist based on your current health status and medication profile.
            Please discuss any concerns or questions with your healthcare provider.
          </div>
        </body>
      </html>
    `);

    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent>
        <Box sx={{ p: 3 }}>
          {/* Preview Content */}
          <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              Preview Recommendations
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {today}
            </Typography>
          </Box>

          {recommendations.map((rec, index) => (
            <Paper key={rec.id} elevation={1} sx={{ mb: 3, p: 3 }}>
              <Typography variant="h6" color="primary" sx={{ mb: 2 }}>
                {index + 1}. Clinical Recommendation:
              </Typography>
              <Typography variant="body1" sx={{ mb: 3, pl: 2 }}>
                {rec.recommendation}
              </Typography>

              <Typography variant="subtitle1" color="primary" sx={{ mb: 1, fontWeight: 600 }}>
                Clinical Benefits:
              </Typography>
              <Box sx={{ pl: 3 }}>
                {rec.benefits.split('\n').map((benefit, i) => (
                  <Typography key={i} variant="body2" sx={{ mb: 0.5, display: 'flex', alignItems: 'center' }}>
                    <Box component="span" sx={{ mr: 1 }}>•</Box>
                    {benefit.replace('•', '').trim()}
                  </Typography>
                ))}
              </Box>
            </Paper>
          ))}

          <Box sx={{ mt: 4, pt: 2, borderTop: '1px solid', borderColor: 'divider' }}>
            <Typography variant="body2" color="text.secondary" sx={{ textAlign: 'center', fontStyle: 'italic' }}>
              These clinical recommendations have been prepared by your pharmacist based on your current health status and medication profile.
              Please discuss any concerns or questions with your healthcare provider.
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
        <Button
          variant="contained"
          startIcon={<Printer size={20} />}
          onClick={handlePrint}
        >
          Print Recommendations
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// PharmacistRecommendationPad component
const PharmacistRecommendationPad = () => {
  const [recommendations, setRecommendations] = useState<RecommendationWithUI[]>([
    { id: uuidv4(), recommendation: '', benefits: '', isLoadingBenefits: false, isExpanded: true },
  ]);

  // Update addRecommendation to collapse others
  const addRecommendation = useCallback(() => {
    setRecommendations((prev) => [
      ...prev.map(rec => ({ ...rec, isExpanded: false })), // Collapse all existing cards
      { id: uuidv4(), recommendation: '', benefits: '', isLoadingBenefits: false, isExpanded: true }, // New card expanded
    ]);
  }, []);

  // Add a toggle function
  const toggleExpanded = useCallback((id: string) => {
    setRecommendations((prev) =>
      prev.map((rec) => ({
        ...rec,
        isExpanded: rec.id === id ? !rec.isExpanded : rec.isExpanded,
      }))
    );
  }, []);

  // Remove a recommendation
  const removeRecommendation = useCallback((id: string) => {
    setRecommendations((prev) => prev.filter((rec) => rec.id !== id));
  }, []);

  // Update a specific field of a recommendation
  const updateRecommendation = useCallback(
    (id: string, field: keyof Omit<Recommendation, 'id'>, value: string | boolean) => {
      setRecommendations((prev) => prev.map((rec) => (rec.id === id ? { ...rec, [field]: value } : rec)));
    },
    []
  );

  const [printPreviewOpen, setPrintPreviewOpen] = useState(false);

  const handleSubmit = useCallback(() => {
    const validRecommendations = recommendations.filter(
      rec => rec.recommendation.trim() && rec.benefits.trim()
    );

    if (validRecommendations.length === 0) {
      alert('Please add at least one recommendation with benefits');
      return;
    }

    setPrintPreviewOpen(true);
  }, [recommendations]);

  return (
    <Box sx={{ maxWidth: '1200px', mx: 'auto', p: 2 }}>
      <Box sx={{ mb: 2 }}>
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative'
        }}>
          <Box sx={{
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            textAlign: 'center',
            width: 'auto'
          }}>
            <Typography variant="h4" color="primary" sx={{ fontWeight: 'bold' }}>
              777 Recommendation Pad
            </Typography>
            {/* <Typography variant="subtitle2" color="text.secondary">
              Create and manage your patient recommendations
            </Typography> */}
          </Box>
          <Box sx={{ ml: 'auto' }}>
            <img
              src={logo777}
              alt="777 Pharmacy Logo"
              style={{
                width: '200px',
                height: '53px',
                objectFit: 'contain'
              }}
            />
          </Box>
        </Box>
      </Box>

      <AnimatePresence>
        {recommendations.map((rec, index) => (
          <RecommendationCard
            key={rec.id}
            rec={rec}
            index={index}
            updateRecommendation={updateRecommendation}
            removeRecommendation={removeRecommendation}
            isLast={recommendations.length === 1}
            isExpanded={rec.isExpanded}
            toggleExpanded={toggleExpanded}
          />
        ))}
      </AnimatePresence>

      <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.3 }}>
        <IconButton
          onClick={addRecommendation}
          color="primary"
          sx={{
            width: '100%',
            height: '48px',
            borderRadius: 1,
            border: '2px dashed',
            borderColor: 'primary.main',
            '&:hover': {
              bgcolor: 'primary.main',
              color: 'white',
            },
          }}
        >
          <Plus size={24} />
        </IconButton>
      </motion.div>

      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.4 }}
        >
          <Button
            variant="contained"
            size="large"
            onClick={handleSubmit}
            startIcon={<Send size={20} />}
            sx={{
              minWidth: 200,
              py: 1.5,
            }}
          >
            Submit Recommendations
          </Button>
        </motion.div>
      </Box>

      <PrintPreview
        open={printPreviewOpen}
        onClose={() => setPrintPreviewOpen(false)}
        recommendations={recommendations.filter(rec => rec.recommendation.trim() && rec.benefits.trim())}
      />
    </Box>
  );
};

export default PharmacistRecommendationPad;
