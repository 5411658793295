import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Typography,
  Avatar,
  Chip,
  Grid,
  Paper,
  Divider,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Modal,
  CircularProgress,
  Tooltip,
  Alert,
} from '@mui/material';
import { Close, LocationOn, Cake, Check, Clear, Info, Save, Message } from '@mui/icons-material';
import { PatientDetails, DrugCalendar } from '../types/patient';
import axios from 'axios';
import { logger } from '../utils/logger';
import AuthContext from '../contexts/AuthContext';
import SignatureCanvas from 'react-signature-canvas';
import useSmsEndpoint from '../hooks/useSmsEndpoint';
import { PharmacySMSSetting } from '../interfaces/healthcare-reservation/ISmsPayload';

interface PatientPrescriptionModalProps {
  open: boolean;
  onClose: () => void;
  eventDate?: string;
  patientDetails?: PatientDetails;
  drugDetails?: DrugCalendar;
  eventId?: string;
  calendarId?: string;
  onEventUpdate?: () => void;
  pharmacyID: string;
  pharmacyName: string;
  pharmacistID: string;
  pharmacistName: string;
}

type FrequencyType = 'STANDARD' | 'WEEKLY_SPECIFIC' | 'EVERY_N_DAYS';
type WeekDay = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';

const formatFrequencyDisplay = (frequency: string): string => {
  if (!frequency) return 'Daily';

  if (frequency.startsWith('WEEKLY_ON_')) {
    const days = frequency
      .replace('WEEKLY_ON_', '')
      .split('_')
      .map((day) => day.charAt(0) + day.slice(1).toLowerCase())
      .join(' and ');
    return `Every ${days}`;
  }

  if (frequency.startsWith('EVERY_') && frequency.endsWith('_DAYS')) {
    const days = frequency.replace('EVERY_', '').replace('_DAYS', '');
    return `Every ${days} days`;
  }

  // Handle standard frequencies
  const standardFrequencies: Record<string, string> = {
    DAILY: 'Daily',
    ALTERNATE_DAYS: 'Alternate Days',
    WEEKLY: 'Weekly',
    FORTNIGHTLY: 'Fortnightly',
    MONTHLY: 'Monthly',
  };

  return standardFrequencies[frequency] || frequency;
};

interface SignaturePadProps {
  onSave: (signature: string) => void;
  disabled?: boolean;
}

const SignaturePad: React.FC<SignaturePadProps> = ({ onSave, disabled }) => {
  const sigPad = React.useRef<SignatureCanvas>(null);
  const [hasSignature, setHasSignature] = useState(false);
  const { pharmacyName } = useContext(AuthContext);

  const clear = () => {
    sigPad.current?.clear();
    setHasSignature(false);
  };

  const save = () => {
    if (sigPad.current && !sigPad.current.isEmpty()) {
      const signatureData = sigPad.current.toDataURL();
      onSave(signatureData);
    }
  };

  return (
    <Box sx={{ border: '1px solid #e0e0e0', borderRadius: 1, p: 0.5 }}>
      <Box
        sx={{
          border: '1px solid #ccc',
          borderRadius: '4px',
          backgroundColor: disabled ? '#f5f5f5' : '#fff',
        }}
      >
        <SignatureCanvas
          ref={sigPad}
          canvasProps={{
            width: 500,
            height: 120,
            className: 'signature-canvas',
            style: { width: '100%', height: '120px' },
          }}
          backgroundColor={disabled ? '#f5f5f5' : '#fff'}
          onEnd={() => setHasSignature(true)}
        />
      </Box>
      <Box sx={{ mt: 1, display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
        <Button size="small" variant="outlined" onClick={clear} disabled={!hasSignature || disabled}>
          Clear
        </Button>
        <Button
          size="small"
          variant="contained"
          onClick={save}
          disabled={!hasSignature || disabled}
          startIcon={<Save />}
        >
          Save Signature
        </Button>
      </Box>
    </Box>
  );
};

// Add this interface for signature status
interface SignatureStatus {
  exists: boolean;
  url?: string;
  requestSent?: boolean;
  requestSentAt?: string;
  verificationCode?: string;
}

const formatPhoneNumber = (value: string) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength <= 3) return phoneNumber;
  if (phoneNumberLength <= 6) {
    return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3)}`;
  }
  return `${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, 10)}`;
};

const PatientPrescriptionModal: React.FC<PatientPrescriptionModalProps> = ({
  open,
  onClose,
  eventDate,
  patientDetails,
  drugDetails,
  eventId,
  calendarId,
  onEventUpdate,
  pharmacyID,
  pharmacyName,
  pharmacistID,
  pharmacistName,
}) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [signature, setSignature] = useState<string | null>(null);
  const [signatureStatus, setSignatureStatus] = useState<SignatureStatus>({ exists: false });
  const [sendingRequest, setSendingRequest] = useState(false);
  const [pharmacySMSSettings, setPharmacySMSSettings] = useState<PharmacySMSSetting | null>(null);
  const smsApi = useSmsEndpoint();
  const [checkingSignature, setCheckingSignature] = useState(true);

  const handleEventUpdate = async (status: 'ADMINISTERED' | 'MISSED') => {
    if (!eventId || !calendarId || !signature) {
      logger.error('Missing required data', { eventId, calendarId, hasSignature: !!signature });
      return;
    }

    setIsUpdating(true);
    try {
      // Helper function to format date
      const formatDate = (dateStr: string | undefined): string => {
        if (!dateStr) return new Date().toISOString().split('T')[0];

        // Handle DD/MM/YYYY format
        if (dateStr.includes('/')) {
          const [day, month, year] = dateStr.split('/');
          return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        }

        // Try to parse the date
        try {
          const date = new Date(dateStr);
          if (isNaN(date.getTime())) {
            throw new Error('Invalid date');
          }
          return date.toISOString().split('T')[0];
        } catch {
          return new Date().toISOString().split('T')[0];
        }
      };

      const formattedDate = formatDate(eventDate);

      // Make both API calls in parallel using Promise.all
      await Promise.all([
        // First API call - Create history
        axios.post(`${process.env.REACT_APP_STAGED_SUPPLY_API}/createStagedSuppHist`, {
          eventId,
          calendarId,
          actionType: status,
          previousStatus: drugDetails?.status,
          newStatus: status,
          drugName: drugDetails?.drugName,
          drugStrength: drugDetails?.drugStrength,
          frequency: drugDetails?.frequency,
          supplyDurationInDays: drugDetails?.supplyDurationInDays,
          quantityPerSupply: drugDetails?.quantityPerSupply,
          patientId: patientDetails?.patientID,
          patientName: patientDetails?.name,
          medicareNumber: patientDetails?.medicareNumber,
          pharmacyId: pharmacyID,
          pharmacyName: pharmacyName,
          pharmacistId: pharmacistID,
          pharmacistName: pharmacistName,
          eventDate: formattedDate,
          actionTimestamp: new Date().toISOString(),
          scheduledTime: '00:00:00',
          notes: `Status updated to ${status}`,
          reason: `Manual update by pharmacist`,
          ipAddress: window.location.hostname,
          userAgent: navigator.userAgent,
          systemVersion: '1.0.0',
          signature: signature,
        }),

        // Second API call - Update event status
        axios.post(`${process.env.REACT_APP_STAGED_SUPPLY_API}/updateStagedSupplyEvent`, {
          calendarID: calendarId,
          eventID: eventId,
          status: status,
        }),
      ]);

      if (onEventUpdate) {
        onEventUpdate();
      }
      onClose();
    } catch (error) {
      logger.error('Error updating event status:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const isActionDisabled = (currentStatus: string) => {
    return currentStatus === 'ADMINISTERED' || currentStatus === 'MISSED' || isUpdating;
  };

  const getButtonTooltip = (status: string) => {
    if (status === 'ADMINISTERED') {
      return 'This dose has already been administered';
    }
    if (status === 'MISSED') {
      return 'This dose has already been marked as missed';
    }
    return '';
  };

  // Add function to get SMS settings
  const getSmsSettingsByPharmacyID = async () => {
    try {
      const request = await fetch(process.env.REACT_APP_SMS_CONFIGURATION_URL || '', {
        method: 'POST',
        body: JSON.stringify({
          operation: 'getSMSConfigurationsByPharmacyID',
          input: {
            pharmacyID,
            limit: 10,
            sortDirection: 'DESC',
          },
        }),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const response = await request.json();
      if (Array.isArray(response?.items) && response?.items?.length > 0) {
        setPharmacySMSSettings(response.items[0]);
        return response.items[0];
      }
      return null;
    } catch (error) {
      logger.error('Error getting SMS settings:', error);
      return null;
    }
  };

  // Add useEffect to fetch SMS settings on mount
  useEffect(() => {
    getSmsSettingsByPharmacyID();
  }, []);

  // Update the handleSendSignatureRequest function
  const handleSendSignatureRequest = async () => {
    setSendingRequest(true);
    try {
      if (!smsApi || !pharmacySMSSettings) {
        logger.error('SMS API or settings not initialized');
        return;
      }

      const verificationCode = Math.floor(1000 + Math.random() * 9000).toString();
      const signatureLink = `${process.env.REACT_APP_SIGNATURE_STAGED_SUPPLY_API}/ss/${eventId}/${calendarId}`;
      let phoneNumber = patientDetails?.phone || '';

      if (phoneNumber.startsWith('0')) {
        phoneNumber = phoneNumber.slice(1);
      }
      phoneNumber = `+61${phoneNumber.replace(/\s/g, '')}`;

      // Update event with verification code
      await axios.post(`${process.env.REACT_APP_STAGED_SUPPLY_API}/updateStagedSupplyEvent`, {
        calendarID: calendarId,
        eventID: eventId,
        verificationCode,
        status: drugDetails?.status || 'PENDING',
      });

      // Send SMS with correct payload format
      const smsPayload = {
        message: `Please sign your staged supply collection at: ${signatureLink}, ${pharmacyName}`,
        contacts: [phoneNumber],
        sender: String(pharmacySMSSettings.dedicatedNumber).trim().replaceAll('+', ''),
      };

      const response = await smsApi.post('/directMessage', smsPayload);

      if (response.status !== 200) {
        throw new Error('Failed to send SMS');
      }

      setSignatureStatus((prev) => ({
        ...prev,
        requestSent: true,
        requestSentAt: new Date().toISOString(),
        verificationCode,
      }));
    } catch (error) {
      logger.error('Error sending signature request:', error);
    } finally {
      setSendingRequest(false);
    }
  };

  // Update the useEffect that checks signature status
  useEffect(() => {
    const checkSignature = async () => {
      if (!eventId || !calendarId) return;

      setCheckingSignature(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_STAGED_SUPPLY_API}/getSignatureStatus`, {
          eventId,
          calendarId,
        });

        if (response.data.signature) {
          setSignatureStatus({
            exists: true,
            url: response.data.signature,
          });
          setSignature(response.data.signature);
        }
      } catch (error) {
        logger.error('Error checking signature status:', error);
      } finally {
        setCheckingSignature(false);
      }
    };

    checkSignature();
  }, [eventId, calendarId]);

  // Replace the SignaturePad component with this new section
  const renderSignatureSection = () => {
    const signatureBoxStyle = {
      height: '120px', // Fixed height matching the awaiting state
      border: '1px solid #e0e0e0',
      borderRadius: 1,
      p: 2,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden', // Prevent overflow
    };

    if (signatureStatus.exists && signatureStatus.url) {
      return (
        <Box sx={signatureBoxStyle}>
          <img
            src={signatureStatus.url}
            alt="Patient Signature"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain', // Maintain aspect ratio
              width: 'auto',
              height: 'auto',
            }}
          />
        </Box>
      );
    }

    return (
      <Box sx={{ border: '1px solid #e0e0e0', borderRadius: 1, p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography variant="body2" sx={{ flex: 1 }}>
            Patient Phone: {patientDetails?.phone ? formatPhoneNumber(patientDetails.phone) : 'N/A'}
          </Typography>
          <Button
            variant="contained"
            size="small"
            onClick={handleSendSignatureRequest}
            disabled={sendingRequest || !patientDetails?.phone || checkingSignature}
            startIcon={checkingSignature || sendingRequest ? <CircularProgress size={16} /> : <Message />}
          >
            {checkingSignature ? 'Checking...' : sendingRequest ? 'Sending...' : 'Request Signature'}
          </Button>
        </Box>

        {signatureStatus.requestSent ? (
          <>
            <Alert severity="info" sx={{ mb: 2 }}>
              Signature request sent to patient at {new Date(signatureStatus.requestSentAt!).toLocaleTimeString()}
            </Alert>
            <Box sx={signatureBoxStyle}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h3" sx={{ letterSpacing: 8, fontWeight: 'bold', color: 'primary.main', mb: 1 }}>
                  {signatureStatus.verificationCode}
                </Typography>
                <Typography color="text.secondary" variant="caption">
                  Please provide this code to the patient
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          <Box sx={signatureBoxStyle}>
            <Typography color="text.secondary">Awaiting patient signature...</Typography>
          </Box>
        )}

        {!patientDetails?.phone && (
          <Alert severity="warning" sx={{ mt: 2 }}>
            No phone number available for this patient
          </Alert>
        )}
      </Box>
    );
  };

  if (!patientDetails || !drugDetails) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="patient-prescription-modal"
      aria-describedby="patient-prescription-details"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 800,
          bgcolor: 'background.paper',
          p: 3,
          borderRadius: 2,
          maxHeight: '90vh',
          overflowY: 'auto',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" component="h2">
            {eventDate || 'No date specified'}
          </Typography>
          <Close sx={{ cursor: 'pointer' }} onClick={onClose} />
        </Box>

        <Paper sx={{ bgcolor: '#F0F4F8', p: 2, mb: 2 }}>
          <Box display="flex" alignItems="center">
            <Avatar src="/api/placeholder/80/80" alt={patientDetails.name} sx={{ width: 60, height: 60, mr: 2 }} />
            <Box>
              <Typography variant="h6">{patientDetails.name}</Typography>
              <Box display="flex" alignItems="center" mb={0.5}>
                <LocationOn fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2">{patientDetails.address}</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Cake fontSize="small" sx={{ mr: 0.5 }} />
                <Typography variant="body2">{patientDetails.dateOfBirth}</Typography>
              </Box>
            </Box>
            <Chip
              label={patientDetails.status || 'Active'}
              color={patientDetails.status === 'inactive' ? 'error' : 'success'}
              size="small"
              sx={{ ml: 'auto' }}
            />
          </Box>
        </Paper>

        <Paper sx={{ bgcolor: '#F0F4F8', p: 2, mb: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Script Details
          </Typography>
          <TableContainer component={Paper} elevation={0}>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Drug name
                  </TableCell>
                  <TableCell>{drugDetails.drugName}</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>
                    <Chip
                      label={drugDetails.status}
                      color={
                        drugDetails.status === 'ADMINISTERED'
                          ? 'success'
                          : drugDetails.status === 'MISSED'
                          ? 'error'
                          : 'default'
                      }
                      size="small"
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Drug Strength
                  </TableCell>
                  <TableCell>{drugDetails.drugStrength}</TableCell>
                  <TableCell>Quantity Per Supply</TableCell>
                  <TableCell>{drugDetails.quantityPerSupply}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Start Date
                  </TableCell>
                  <TableCell>{drugDetails.startDate}</TableCell>
                  <TableCell>Supply Duration</TableCell>
                  <TableCell>{drugDetails.supplyDurationInDays} days</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    End Date
                  </TableCell>
                  <TableCell colSpan={3}>{drugDetails.endDate}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Collection Frequency
                  </TableCell>
                  <TableCell>{formatFrequencyDisplay(drugDetails.frequency)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>

        <Paper sx={{ bgcolor: '#F0F4F8', p: 1, mb: 1 }}>
          <Typography variant="subtitle1" gutterBottom>
            Patient Signature
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
            Patient signature required for {drugDetails?.status === 'ADMINISTERED' ? 'receipt' : 'missed dose'} of
            medication
          </Typography>
          {renderSignatureSection()}
        </Paper>

        <Box display="flex" justifyContent="space-between">
          <Tooltip
            title={
              !signatureStatus.exists
                ? 'Patient signature required'
                : isActionDisabled(drugDetails?.status || '')
                ? getButtonTooltip(drugDetails?.status || '')
                : ''
            }
            placement="top"
          >
            <span style={{ flex: 1, marginRight: 8 }}>
              <Button
                variant="outlined"
                startIcon={isUpdating ? <CircularProgress size={20} /> : <Check />}
                sx={{ width: '100%' }}
                onClick={() => handleEventUpdate('ADMINISTERED')}
                disabled={!signatureStatus.exists || isActionDisabled(drugDetails?.status || '')}
              >
                {isUpdating
                  ? 'Updating...'
                  : drugDetails?.status === 'ADMINISTERED'
                  ? 'Already Administered'
                  : 'Administered Dose'}
              </Button>
            </span>
          </Tooltip>
          <Tooltip
            title={
              !signatureStatus.exists
                ? 'Patient signature required'
                : isActionDisabled(drugDetails?.status || '')
                ? getButtonTooltip(drugDetails?.status || '')
                : ''
            }
            placement="top"
          >
            <span style={{ flex: 1, marginLeft: 8 }}>
              <Button
                variant="outlined"
                color="error"
                startIcon={isUpdating ? <CircularProgress size={20} /> : <Clear />}
                sx={{ width: '100%' }}
                onClick={() => handleEventUpdate('MISSED')}
                disabled={!signatureStatus.exists || isActionDisabled(drugDetails?.status || '')}
              >
                {isUpdating ? 'Updating...' : drugDetails?.status === 'MISSED' ? 'Marked as Missed' : 'Dose Missed'}
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Box>
    </Modal>
  );
};

export default PatientPrescriptionModal;
