import React from 'react'

type Props = {}

const OrderDetail = (props: Props) => {
  return (
    <div>OrderDetail</div>
  )
}

export default OrderDetail