import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { logger } from '../utils/logger';
import dayjs from 'dayjs';

interface FollowupRequest {
  patientId: string;
  patientPhone: string;
  patientName: string;
  reminderType: 'INJECTION' | 'VACCINATION' | 'MEDICATION' | 'UTI';
  reminderDate: string;
  message: string;
  pharmacistID: string;
  pharmacyID: string;
  medicationsPrescribed?: string;
  followupType: string;
  preferredContactMethod: string;
}

export const scheduleFollowup = async (data: FollowupRequest) => {
  try {
    const apiUrl = process.env.REACT_APP_PATIENT_FOLLOWUPS_CREATE_URL;
    if (!apiUrl) {
      throw new Error('REACT_APP_PATIENT_FOLLOWUPS_CREATE_URL is not defined');
    }

    const followupData = {
      id: uuidv4(),
      patientID: data.patientId,
      pharmacistID: data.pharmacistID,
      pharmacyID: data.pharmacyID,
      patientName: data.patientName,
      contactPhone: data.patientPhone,
      preferredContactMethod: data.preferredContactMethod,
      visitDate: dayjs().format('YYYY-MM-DD'),
      followupDate: dayjs(data.reminderDate).format('YYYY-MM-DD'),
      followupTime: dayjs(data.reminderDate).format('HH:mm'),
      followupType: data.followupType,
      visitReason: data.reminderType,
      followupReason: `Reminder for ${data.reminderType}`,
      reminderSet: true,
      status: 'pending',
      message: data.message,
    };

    const response = await axios.post(apiUrl, followupData);
    return response.data;
  } catch (error) {
    logger.error('Error scheduling followup:', error);
    throw error;
  }
};
