import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Box,
  Paper,
  Grid,
  Container,
  ThemeProvider,
  createTheme,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  Alert,
  Select,
  MenuItem,
  InputLabel,
  SelectChangeEvent,
  StepConnector,
  stepConnectorClasses,
  CircularProgress,
  Snackbar,
  Backdrop,
} from '@mui/material';
import { blue, grey, red, orange, green } from '@mui/material/colors';
import { UserPlus, AlertCircle, AlertTriangle, CheckCircle, XCircle } from 'lucide-react';
import { logger } from '../../../../utils/logger';
import { styled } from '@mui/material/styles';
import { useForm, Control, FieldPath } from 'react-hook-form';
import { getPharmacistInfo, PharmacistActionStatus, PharmacistInfo } from '../../../../utils/decisionTree';
import Step1PatientInfo from './Steps/Step1PatientInfo';
import Step2PatientConsent from './Steps/Step2PatientConsent';
import Step3Symptoms from './Steps/Step3Symptoms';
import Step4HighRiskFactors from './Steps/Step4HighRiskFactors';
import Step5ModerateRiskFactors from './Steps/Step5ModerateRiskFactors';
import Step6Diagnosis from './Steps/Step6Diagnosis';
import Step7Treatment from './Steps/Step7Treatment';
import Step8FollowUp from './Steps/Step8FollowUp';
import Step9Referral from './Steps/Step9Referral';
import ProductCard from '../../../../components/Safe/Sale/SaleProduct/ProductCard';
import { useUTIProducts } from '../../../../hooks/useUTIProducts';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useCustomer } from 'contexts/CustomerContext';
import { useIdToken } from 'hooks/useIdToken';
import { v4 as uuidv4 } from 'uuid';
import AuthContext from '../../../../contexts/AuthContext';
import SummaryScreen from './SummaryScreen';
import { scheduleFollowup } from 'services/followupService';

const theme = createTheme({
  palette: {
    primary: blue,
    background: {
      default: grey[100],
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '24px',
          marginBottom: '24px',
        },
      },
    },
  },
});

export interface FormData {
  patientInfo: {
    firstName: string;
    lastName: string;
    dateOfBirth: string;
    gender: string;
    genderReassignment: boolean;
    phoneNumber: string; // Add this line
  };
  patientConsent: {
    consentGiven: boolean;
    physicallyPresent: boolean;
  };
  symptoms: {
    temperature: string;
    pyelonephritisSigns: boolean;
    suspectedSepsis: boolean;
    dysuria: boolean;
    frequency: boolean;
    urgency: boolean;
    suprapubicPainOrDiscomfort: boolean;
    otherCause: boolean;
  };
  riskFactors: {
    highRisk: {
      pregnancy: boolean;
      recurrentUTI: boolean;
      persistentSymptoms: boolean;
      reoccurringSymptoms: boolean;
      visibleHaematuria: boolean;
      pyuria: boolean;
      renalDisease: boolean;
      catheterisation: boolean;
      pyelonephritis: boolean;
      immunocompromised: boolean;
      bloodDisorder: boolean;
      spinalCordInjury: boolean;
      diabetes: boolean;
      recentIUD: boolean;
    };
    moderateRisk: {
      recentAntibiotics: boolean;
      recentHealthcareFacility: boolean;
      recentOverseasTravel: boolean;
      multidrugResistantBacteria: boolean;
      stiRiskAge: boolean;
      stiRiskSexualContact: boolean;
      sglt2Inhibitors: boolean;
      iudInSitu: boolean;
    };
  };
  urineDipstick: {
    leukocytes: string;
    nitrites: string;
  };
  diagnosis: string;
  treatment: {
    analgesia: {
      paracetamol: boolean;
      ibuprofen: boolean;
      naproxen: boolean;
      dosageInstructions: string;
    };
    alkalinisingAgent: boolean;
    alkalinisingAgentDosageInstructions: string;
    selfCareAdvice: boolean;
    utiProphylaxisEducation: boolean;
    adviceDetails: string;
    medicineProvided: string;
    dosageInstructions: string;
    quantityConfirmation: boolean;
    dispensedViaSoftware: boolean;
    labelingCompliance: boolean;
    dispensingComments: string;
  };
  followUp: {
    symptomResolutionAdvised: boolean;
    urineSampleProvided: boolean;
    worseningSymptomAdvised: boolean;
    sideEffectsAdvised: boolean;
    recurrenceAdvised: boolean;
    shareConsultationRecord: boolean;
    additionalNotes: string;
  };
  referral: string;
  referralNotes: string;
  pharmacistCare: {
    followUpDate: string;
    followUpMethod: string[];
    followUpNotes: string;
  };
}

const steps = [
  'Patient Information & Consent',
  'Symptoms',
  'High Risk Factors',
  'Moderate Risk Factors',
  'Diagnosis',
  'Treatment',
  'Follow-up Plan',
  'Referral',
];

const VerticalStepperConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderLeftWidth: 3,
    borderRadius: 1,
  },
}));

interface UTIProtocolFormProps {
  onSubmit: (data: FormData) => void;
}

const UTIProtocolForm: React.FC<UTIProtocolFormProps> = ({ onSubmit }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [pharmacistInfo, setPharmacistInfo] = useState<PharmacistInfo>({
    message: '',
    status: PharmacistActionStatus.Info,
  });
  const { loading, error, products } = useUTIProducts(2); // Fetch only 2 products
  const { state } = useCustomer();
  const { customer } = state;
  const navigate = useNavigate();
  const { idToken, isLoading: isTokenLoading, error: idTokenError } = useIdToken();
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
  const { pharmacyID, providerNumber, providerFirstName, providerLastName, providerAHPRA, pharmacyName } =
    useContext(AuthContext);
  const [showSummary, setShowSummary] = useState(false);
  const [submittedData, setSubmittedData] = useState<FormData | null>(null);
  const {
    control,
    handleSubmit: handleFormSubmit,
    watch,
    setValue,
  } = useForm<FormData>({
    defaultValues: {
      patientInfo: {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        gender: '',
        genderReassignment: false,
        phoneNumber: '', // Add this line
      },
      patientConsent: { consentGiven: false, physicallyPresent: false },
      symptoms: {
        temperature: '',
        pyelonephritisSigns: false,
        suspectedSepsis: false,
        dysuria: false,
        frequency: false,
        urgency: false,
        suprapubicPainOrDiscomfort: false,
        otherCause: false,
      },
      riskFactors: {
        highRisk: {
          pregnancy: false,
          recurrentUTI: false,
          persistentSymptoms: false,
          reoccurringSymptoms: false,
          visibleHaematuria: false,
          pyuria: false,
          renalDisease: false,
          catheterisation: false,
          pyelonephritis: false,
          immunocompromised: false,
          bloodDisorder: false,
          spinalCordInjury: false,
          diabetes: false,
          recentIUD: false,
        },
        moderateRisk: {
          recentAntibiotics: false,
          recentHealthcareFacility: false,
          recentOverseasTravel: false,
          multidrugResistantBacteria: false,
          stiRiskAge: false,
          stiRiskSexualContact: false,
          sglt2Inhibitors: false,
          iudInSitu: false,
        },
      },
      urineDipstick: { leukocytes: '', nitrites: '' },
      diagnosis: '',
      treatment: {
        analgesia: {
          paracetamol: false,
          ibuprofen: false,
          naproxen: false,
          dosageInstructions: '',
        },
        alkalinisingAgent: false,
        alkalinisingAgentDosageInstructions: '',
        selfCareAdvice: false,
        utiProphylaxisEducation: false,
        adviceDetails: '',
        medicineProvided: '',
        dosageInstructions: '',
        quantityConfirmation: false,
        dispensedViaSoftware: false,
        labelingCompliance: false,
        dispensingComments: '',
      },
      followUp: {
        symptomResolutionAdvised: false,
        urineSampleProvided: false,
        worseningSymptomAdvised: false,
        sideEffectsAdvised: false,
        recurrenceAdvised: false,
        shareConsultationRecord: false,
        additionalNotes: '',
      },
      referral: '',
      referralNotes: '',
      pharmacistCare: {
        followUpDate: dayjs().add(3, 'day').toISOString(),
        followUpMethod: [],
        followUpNotes: '',
      },
    },
  });

  useEffect(() => {
    if (!customer) {
      logger.log('No customer selected');
      navigate('/pages/patients/search-patient');
    } else {
      // Pre-fill the form with customer information
      setValue('patientInfo', {
        firstName: customer.firstName || '',
        lastName: customer.lastName || '',
        dateOfBirth: customer.dateOfBirth ? dayjs(customer.dateOfBirth).format('YYYY-MM-DD') : '',
        gender: customer.gender || '',
        genderReassignment: false, // You might want to update this based on your customer data structure
        phoneNumber: customer.phone || '',
      });
    }
  }, [customer, navigate, setValue]);

  const formData = watch();

  const temperature = parseFloat(formData.symptoms.temperature);
  const relevantFormData = useMemo(
    () => ({
      patientInfo: formData.patientInfo,
      patientConsent: formData.patientConsent,
      symptoms: formData.symptoms,
      riskFactors: formData.riskFactors,
      urineDipstick: formData.urineDipstick,
      diagnosis: formData.diagnosis,
      treatment: formData.treatment,
      followUp: formData.followUp,
      referral: formData.referral,
      referralNotes: formData.referralNotes,
      pharmacistCare: formData.pharmacistCare,
    }),
    [formData]
  );

  const updatePharmacistInfo = useCallback(() => {
    const info = getPharmacistInfo(activeStep, relevantFormData as FormData);
    setPharmacistInfo(info);
  }, [activeStep, relevantFormData]);

  useEffect(() => {
    if (!isNaN(temperature) && temperature > 38) {
      setValue('symptoms.pyelonephritisSigns', true);
    }
  }, [temperature, setValue]);

  useEffect(() => {
    updatePharmacistInfo();
  }, [updatePharmacistInfo, formData]);

  const handleChange = (section: keyof FormData, field: string, value: string | boolean) => {
    setValue(`${section}.${field}` as FieldPath<FormData>, value);
  };

  const handleSubmit = async (data: FormData) => {
    if (isTokenLoading || !idToken) {
      console.error('ID token not available');
      return;
    }

    setIsLoading(true);

    const utiRecord = {
      utiRecordID: uuidv4(),
      patientID: customer?.patientID,
      pharmacyID: pharmacyID,
      pharmacistAPHRA: providerAHPRA,
      state: 'WA',
      ...data,
    };

    try {
      const apiCreateUTIRecord = process.env.REACT_APP_CREATE_UTI_RECORD;
      if (!apiCreateUTIRecord) {
        throw new Error('REACT_APP_CREATE_UTI_RECORD is not defined');
      }

      const response = await fetch(apiCreateUTIRecord, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({ input: utiRecord }),
      });

      if (!response.ok) {
        throw new Error('Failed to create UTI record');
      }

      const responseData = await response.json();

      // Schedule follow-up if methods are selected
      if (data.pharmacistCare.followUpMethod.length > 0) {
        try {
          await scheduleFollowup({
            patientId: customer?.patientID!,
            patientPhone: data.patientInfo.phoneNumber,
            patientName: `${data.patientInfo.firstName} ${data.patientInfo.lastName}`,
            reminderType: 'UTI',
            reminderDate: data.pharmacistCare.followUpDate,
            message: `Hello, this is ${providerFirstName} from ${pharmacyName}. Just checking in to see how you’re feeling. If your symptoms haven’t improved or have worsened, please don’t hesitate to visit us or consult your GP for further care`,
            pharmacistID: providerNumber,
            pharmacyID: pharmacyID,
            medicationsPrescribed: data.treatment.medicineProvided,
            followupType: data.pharmacistCare.followUpMethod.includes('text') ? 'sms' : 'phone',
            preferredContactMethod: data.pharmacistCare.followUpMethod.includes('text') ? 'SMS' : 'PHONE',
          });
        } catch (error) {
          logger.error('Failed to schedule follow-up:', error);
          setSnackbar({
            open: true,
            message: 'UTI record created but follow-up scheduling failed',
            severity: 'warning',
          });
        }
      }

      logger.log('UTI record created successfully:', responseData);
      setSnackbar({ open: true, message: 'UTI record created successfully', severity: 'success' });
      setSubmittedData(data);
      setShowSummary(true);
    } catch (error) {
      console.error('Error creating UTI record:', error);
      setSnackbar({ open: true, message: 'Failed to create UTI record', severity: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return <Step1PatientInfo control={control} formData={formData} />;
      case 1:
        return <Step3Symptoms control={control} formData={formData} handleChange={handleChange} />;
      case 2:
        return <Step4HighRiskFactors control={control} />;
      case 3:
        return <Step5ModerateRiskFactors control={control} />;
      case 4:
        return <Step6Diagnosis control={control} />;
      case 5:
        return <Step7Treatment control={control} step={step} />;
      case 6:
        return <Step8FollowUp control={control} />;
      case 7:
        return <Step9Referral control={control} pharmacistInfo={pharmacistInfo} />;
      default:
        return null;
    }
  };

  const renderPharmacistInfo = () => {
    const { message, status } = pharmacistInfo;

    const statusConfig = {
      [PharmacistActionStatus.Emergency]: { icon: <AlertCircle />, color: red[700] },
      [PharmacistActionStatus.ReferGP]: { icon: <XCircle />, color: orange[700] },
      [PharmacistActionStatus.Proceed]: { icon: <CheckCircle />, color: green[700] },
      [PharmacistActionStatus.Info]: { icon: <AlertTriangle />, color: blue[700] },
    };

    const { icon, color } = statusConfig[status];

    return (
      <Alert
        icon={icon}
        severity="info"
        sx={{
          backgroundColor: color,
          color: 'white',
          '& .MuiAlert-icon': {
            color: 'white',
          },
        }}
      >
        <Typography variant="h6" component="div" sx={{ mb: 1 }}>
          Pharmacist Action
        </Typography>
        <Typography variant="body1">{message}</Typography>
      </Alert>
    );
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  if (showSummary && submittedData) {
    return <SummaryScreen formData={submittedData} onPrint={handlePrint} />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Paper elevation={3}>
        <Box sx={{ p: { xs: 1, sm: 2 }, height: 'calc(100vh - 140px)' }}>
          <Grid container spacing={2} sx={{ height: '100%' }}>
            <Grid item xs={12} md={3} lg={2} sx={{ height: '100%' }}>
              <Paper elevation={3} sx={{ p: 2, height: '100%', overflow: 'auto' }}>
                <Stepper activeStep={activeStep} orientation="vertical" connector={<VerticalStepperConnector />}>
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={7} sx={{ height: '100%' }}>
              <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ flexGrow: 1, overflow: 'auto', mb: 2 }}>{renderStepContent(activeStep)}</Box>
                <Box sx={{ mt: 'auto' }}>
                  <Button disabled={activeStep === 0} onClick={handleBack} size="small" sx={{ mr: 1 }}>
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    onClick={activeStep === steps.length - 1 ? handleFormSubmit(handleSubmit) : handleNext}
                    size="small"
                  >
                    {activeStep === steps.length - 1 ? 'Submit' : 'Continue'}
                  </Button>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3} sx={{ height: '100%' }}>
              <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" gutterBottom>
                  Information for Pharmacist
                </Typography>
                <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
                  {renderPharmacistInfo()}
                  {/* {activeStep === 5 && (
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="h6" gutterBottom>
                        Recommended Products
                      </Typography>
                      {loading ? (
                        <CircularProgress />
                      ) : error ? (
                        <Typography color="error">Error loading products</Typography>
                      ) : (
                        <Grid container spacing={1}>
                          {products.map((product) => (
                            <ProductCard
                              key={product.productID}
                              product={product}
                              identificationNumber={formData.patientInfo.firstName + formData.patientInfo.lastName}
                            />
                          ))}
                        </Grid>
                      )}
                    </Box>
                  )} */}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity as 'success' | 'error'}
          sx={{ width: '100%' }}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </ThemeProvider>
  );
};

export default UTIProtocolForm;
