'use client';

import React, { useState } from 'react';
import {
  Bell,
  Calendar,
  Download,
  FileText,
  MessageSquare,
  Search,
  LayoutDashboard,
  BarChart2,
  Clock,
  AlertCircle,
  CheckCircle,
  AlertTriangle,
  RefreshCcw,
} from 'lucide-react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { motion, AnimatePresence } from 'framer-motion';

// Import MUI components to match existing project structure
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Input,
  Tabs,
  Tab,
  LinearProgress,
  Badge,
  Avatar,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Paper,
  Divider,
  Chip,
} from '@mui/material';

// Mock data
const completedMedsChecks = 5;
const targetMedsChecks = 20;
const pendingSubmissions = 12;
const revenueGenerated = 15000;

const trendsData = [
  { month: 'Jan', count: 65, revenue: 9750 },
  { month: 'Feb', count: 75, revenue: 11250 },
  { month: 'Mar', count: 80, revenue: 12000 },
  { month: 'Apr', count: 72, revenue: 10800 },
  { month: 'May', count: 85, revenue: 12750 },
  { month: 'Jun', count: 90, revenue: 13500 },
];

const claimsData = [
  { id: 1, patient: 'John Doe', status: 'pending', date: '2023-06-15' },
  { id: 2, patient: 'Jane Smith', status: 'approved', date: '2023-06-14' },
  { id: 3, patient: 'Bob Johnson', status: 'returned', date: '2023-06-13' },
  { id: 4, patient: 'Alice Brown', status: 'pending', date: '2023-06-16' },
  { id: 5, patient: 'Charlie Davis', status: 'approved', date: '2023-06-12' },
];

// Add these mock data
const recentActivity = [
  {
    id: 1,
    type: 'new',
    message: 'New MedsCheck submitted for Jane Doe',
  },
  {
    id: 2,
    type: 'reminder',
    message: 'Reminder: Follow-up with John Smith tomorrow',
  },
  {
    id: 3,
    type: 'approved',
    message: 'MedsCheck approved for Bob Johnson',
  },
  {
    id: 4,
    type: 'urgent',
    message: "Urgent: Additional information needed for Alice Brown's claim",
  },
];

const upcomingAppointments = [
  {
    id: 1,
    patient: 'Sarah Johnson',
    time: 'Tomorrow, 2:00 PM',
  },
  {
    id: 2,
    patient: 'Michael Brown',
    time: 'Jun 18, 10:30 AM',
  },
  {
    id: 3,
    patient: 'Emily Davis',
    time: 'Jun 20, 3:15 PM',
  },
  {
    id: 4,
    patient: 'David Wilson',
    time: 'Jun 22, 11:00 AM',
  },
];

// Add these mock data if not already present
const performanceData = [
  { metric: 'Completed MedsChecks', value: 75, target: 100, unit: '' },
  { metric: 'Revenue Generated', value: 15000, target: 20000, unit: '$' },
  { metric: 'Average Time to Claim', value: 1.2, target: 1, unit: 'days' },
  { metric: 'Patient Satisfaction', value: 4.5, target: 4.8, unit: '/5' },
];

const recentReportsData = [
  { id: 1, name: 'Monthly Claims Summary', date: '2023-06-01', type: 'PDF' },
  { id: 2, name: 'Quarterly Performance Report', date: '2023-04-01', type: 'CSV' },
  { id: 3, name: 'Annual Revenue Analysis', date: '2023-01-01', type: 'PDF' },
  { id: 4, name: 'Patient Satisfaction Survey', date: '2023-05-15', type: 'CSV' },
  { id: 5, name: 'Efficiency Metrics Report', date: '2023-06-10', type: 'PDF' },
];

// Chart options
const barChartOptions: ApexOptions = {
  chart: {
    type: 'bar',
    height: 300,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 4,
      horizontal: false,
      columnWidth: '60%',
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  xaxis: {
    categories: trendsData.map((data) => data.month),
  },
  yaxis: {
    title: {
      text: 'Number of MedsChecks',
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter: function (val: number) {
        return val + ' MedsChecks';
      },
    },
  },
  colors: ['#2196f3'],
};

const lineChartOptions: ApexOptions = {
  chart: {
    type: 'line',
    height: 300,
    toolbar: {
      show: false,
    },
  },
  stroke: {
    curve: 'smooth',
    width: 3,
  },
  xaxis: {
    categories: trendsData.map((data) => data.month),
  },
  yaxis: {
    title: {
      text: 'Revenue ($)',
    },
  },
  tooltip: {
    y: {
      formatter: function (val: number) {
        return '$' + val.toLocaleString();
      },
    },
  },
  colors: ['#4caf50'],
};

// Add this type for status
type StatusType = 'pending' | 'approved' | 'returned' | 'draft' | 'in_review';

// Add this interface for enhanced status
interface EnhancedStatus {
  label: string;
  color: string;
  icon: JSX.Element;
  description: string;
  bgColor: string;
}

// Add this status config
const statusConfig: Record<StatusType, EnhancedStatus> = {
  pending: {
    label: 'Pending',
    color: '#ed6c02',
    icon: <Clock size={16} />,
    description: 'Awaiting submission',
    bgColor: 'rgba(237, 108, 2, 0.1)',
  },
  approved: {
    label: 'Approved',
    color: '#2e7d32',
    icon: <CheckCircle size={16} />,
    description: 'Successfully processed',
    bgColor: 'rgba(46, 125, 50, 0.1)',
  },
  returned: {
    label: 'Returned',
    color: '#d32f2f',
    icon: <AlertCircle size={16} />,
    description: 'Requires attention',
    bgColor: 'rgba(211, 47, 47, 0.1)',
  },
  draft: {
    label: 'Draft',
    color: '#0288d1',
    icon: <RefreshCcw size={16} />,
    description: 'Not yet submitted',
    bgColor: 'rgba(2, 136, 209, 0.1)',
  },
  in_review: {
    label: 'In Review',
    color: '#ed6c02',
    icon: <AlertTriangle size={16} />,
    description: 'Under assessment',
    bgColor: 'rgba(237, 108, 2, 0.1)',
  },
};

// Add these animation variants
const tabContentVariants = {
  enter: {
    y: 20,
    opacity: 0,
  },
  center: {
    y: 0,
    opacity: 1,
  },
  exit: {
    y: -20,
    opacity: 0,
  },
};

// Update the StatusBadge component
const StatusBadge = ({ status }: { status: StatusType }) => {
  const statusInfo = statusConfig[status];

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: 1,
        px: 1.5,
        py: 0.5,
        borderRadius: 2,
        bgcolor: statusInfo.bgColor,
        color: statusInfo.color,
        transition: 'all 0.3s ease',
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: 1,
        },
      }}
    >
      {statusInfo.icon}
      <Box>
        <Typography variant="caption" sx={{ fontWeight: 500 }}>
          {statusInfo.label}
        </Typography>
        <Typography
          variant="caption"
          sx={{
            display: 'block',
            fontSize: '0.7rem',
            opacity: 0.8,
          }}
        >
          {statusInfo.description}
        </Typography>
      </Box>
    </Box>
  );
};

export default function MedsCheckDashboard() {
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('overview');

  const filteredClaims = claimsData.filter((claim) => claim.patient.toLowerCase().includes(searchTerm.toLowerCase()));

  const barChartSeries = [
    {
      name: 'MedsChecks',
      data: trendsData.map((data) => data.count),
    },
  ];

  const lineChartSeries = [
    {
      name: 'Revenue',
      data: trendsData.map((data) => data.revenue),
    },
  ];

  const getBadgeColor = (status: string) => {
    switch (status) {
      case 'approved':
        return 'success';
      case 'pending':
        return 'warning';
      case 'returned':
        return 'error';
      default:
        return 'default';
    }
  };

  const getActivityIcon = (type: string) => {
    switch (type) {
      case 'new':
        return (
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              bgcolor: 'info.main',
              animation: 'pulse 2s infinite',
              '@keyframes pulse': {
                '0%': { boxShadow: '0 0 0 0 rgba(33, 150, 243, 0.4)' },
                '70%': { boxShadow: '0 0 0 10px rgba(33, 150, 243, 0)' },
                '100%': { boxShadow: '0 0 0 0 rgba(33, 150, 243, 0)' },
              },
            }}
          />
        );
      case 'reminder':
        return (
          <Box sx={{ color: 'warning.main' }}>
            <Bell size={20} />
          </Box>
        );
      case 'approved':
        return (
          <Box
            sx={{
              width: 8,
              height: 8,
              borderRadius: '50%',
              bgcolor: 'success.main',
              animation: 'pulse 2s infinite',
            }}
          />
        );
      case 'urgent':
        return (
          <Box sx={{ color: 'error.main' }}>
            <Bell size={20} />
          </Box>
        );
      default:
        return null;
    }
  };

  const tabs = [
    {
      value: 'overview',
      label: 'Overview',
      icon: <LayoutDashboard size={18} />,
      color: '#2196f3', // primary blue
    },
    {
      value: 'pending',
      label: 'Pending Submissions',
      icon: <Clock size={18} />,
      color: '#ed6c02', // warning orange
    },
    {
      value: 'analytics',
      label: 'Analytics',
      icon: <BarChart2 size={18} />,
      color: '#4caf50', // success green
    },
    {
      value: 'reports',
      label: 'Reports',
      icon: <FileText size={18} />,
      color: '#ff9800', // warning orange
    },
  ];

  return (
    <Box sx={{ flex: 1, p: { xs: 2, md: 4 }, pt: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
        <Typography variant="h4" fontWeight="bold">
          MedsCheck Dashboard
        </Typography>
        <Button variant="contained" startIcon={<Download />} sx={{ textTransform: 'none' }}>
          Download Report
        </Button>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs
          value={activeTab}
          onChange={(_, newValue) => setActiveTab(newValue)}
          variant="fullWidth"
          sx={{
            '& .MuiTab-root': {
              minHeight: '64px',
              textTransform: 'none',
              fontSize: '1rem',
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'translateY(-2px)',
              },
            },
            '& .Mui-selected': {
              fontWeight: 'bold',
            },
            '& .MuiTabs-indicator': {
              height: 3,
              borderRadius: '3px 3px 0 0',
              transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
            },
          }}
        >
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              value={tab.value}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  {React.cloneElement(tab.icon, {
                    style: {
                      color: activeTab === tab.value ? tab.color : 'inherit',
                      transition: 'color 0.3s ease',
                    },
                  })}
                  <span>{tab.label}</span>
                </Box>
              }
              sx={{
                '&.Mui-selected': {
                  color: tab.color,
                },
                '&:hover': {
                  color: tab.color,
                  opacity: 0.8,
                },
              }}
            />
          ))}
        </Tabs>
      </Box>

      <AnimatePresence mode="wait">
        <motion.div
          key={activeTab}
          initial="enter"
          animate="center"
          exit="exit"
          variants={tabContentVariants}
          transition={{ duration: 0.3 }}
        >
          {activeTab === 'overview' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: { xs: '1fr', md: 'repeat(4, 1fr)' } }}>
                <Card>
                  <CardHeader title="Completed MedsChecks" titleTypographyProps={{ variant: 'subtitle2' }} />
                  <CardContent>
                    <Typography variant="h4" fontWeight="bold">
                      {completedMedsChecks}/{targetMedsChecks}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={(completedMedsChecks / targetMedsChecks) * 100}
                      sx={{ mt: 1 }}
                    />
                    <Typography variant="caption" color="text.secondary">
                      {((completedMedsChecks / targetMedsChecks) * 100).toFixed(0)}% of monthly target
                    </Typography>
                  </CardContent>
                </Card>

                <Card>
                  <CardHeader title="Pending Submissions" titleTypographyProps={{ variant: 'subtitle2' }} />
                  <CardContent>
                    <Typography variant="h4" fontWeight="bold">
                      {pendingSubmissions}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      Claims need action
                    </Typography>
                  </CardContent>
                </Card>

                <Card>
                  <CardHeader title="Revenue Generated (MTD)" titleTypographyProps={{ variant: 'subtitle2' }} />
                  <CardContent>
                    <Typography variant="h4" fontWeight="bold">
                      ${revenueGenerated.toLocaleString()}
                    </Typography>
                    <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box component="span" sx={{ color: 'success.main', mr: 0.5 }}>
                        +20.1%
                      </Box>
                      from last month
                    </Typography>
                  </CardContent>
                </Card>

                <Card>
                  <CardHeader title="Efficiency Ratio" titleTypographyProps={{ variant: 'subtitle2' }} />
                  <CardContent>
                    <Typography variant="h4" fontWeight="bold">
                      1.2 days
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      Avg. time from consultation to claim
                    </Typography>
                  </CardContent>
                </Card>
              </Box>

              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: { xs: '1fr', lg: '1fr 1fr' } }}>
                <Card>
                  <CardHeader title="MedsCheck Trends" subheader="Monthly completed MedsChecks" />
                  <CardContent>
                    <Box sx={{ height: 300 }}>
                      <ReactApexChart options={barChartOptions} series={barChartSeries} type="bar" height={300} />
                    </Box>
                  </CardContent>
                </Card>

                <Card>
                  <CardHeader title="Revenue Trends" subheader="Monthly revenue from MedsChecks" />
                  <CardContent>
                    <Box sx={{ height: 300 }}>
                      <ReactApexChart options={lineChartOptions} series={lineChartSeries} type="line" height={300} />
                    </Box>
                  </CardContent>
                </Card>
              </Box>

              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: { xs: '1fr', lg: '4fr 3fr' } }}>
                <Card>
                  <CardHeader title="Claims Queue" />
                  <CardContent>
                    <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                      <Input
                        placeholder="Search claims..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        fullWidth
                      />
                      <IconButton>
                        <Search />
                      </IconButton>
                    </Box>

                    <Box sx={{ maxHeight: 400, overflow: 'auto' }}>
                      {filteredClaims.map((claim) => (
                        <Box
                          key={claim.id}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: 2,
                            borderBottom: '1px solid',
                            borderColor: 'divider',
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Avatar sx={{ width: 40, height: 40 }}>{claim.patient[0]}</Avatar>
                            <Box>
                              <Typography variant="subtitle2">{claim.patient}</Typography>
                              <Typography variant="caption" color="text.secondary">
                                {claim.date}
                              </Typography>
                            </Box>
                          </Box>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <StatusBadge status={claim.status as StatusType} />
                            <Button size="small" variant="outlined">
                              View
                            </Button>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </CardContent>
                </Card>

                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                  {/* <Card>
                    <CardHeader title="Recent Activity" />
                    <CardContent>
                      <Box sx={{ maxHeight: 300, overflow: 'auto' }}>
                        {recentActivity.map((activity) => (
                          <Box
                            key={activity.id}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              mb: 2,
                              p: 1,
                            }}
                          >
                            {getActivityIcon(activity.type)}
                            <Typography variant="body2">{activity.message}</Typography>
                          </Box>
                        ))}
                      </Box>
                    </CardContent>
                  </Card> */}

                  <Card>
                    <CardHeader title="Upcoming Appointments" />
                    <CardContent>
                      <Box sx={{ maxHeight: 300, overflow: 'auto' }}>
                        {upcomingAppointments.map((appointment) => (
                          <Box
                            key={appointment.id}
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              mb: 2,
                              p: 1,
                            }}
                          >
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                              <Calendar size={16} />
                              <Typography variant="body2">{appointment.patient}</Typography>
                            </Box>
                            <Typography variant="caption" color="text.secondary">
                              {appointment.time}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </Box>
            </Box>
          )}

          {activeTab === 'pending' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Input
                    placeholder="Search submissions..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    sx={{ minWidth: 300 }}
                  />
                  <IconButton>
                    <Search />
                  </IconButton>
                </Box>
                <Box>
                  <Select defaultValue="all" size="small" sx={{ mr: 2 }}>
                    <MenuItem value="all">All Status</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="draft">Draft</MenuItem>
                    <MenuItem value="returned">Returned</MenuItem>
                  </Select>
                  <Button variant="contained" startIcon={<Download />}>
                    Export List
                  </Button>
                </Box>
              </Box>

              <Card>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Patient Name</TableCell>
                      <TableCell>Submission Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Issues</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredClaims.map((claim) => (
                      <TableRow key={claim.id} sx={{ '&:hover': { bgcolor: 'action.hover' } }}>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Avatar sx={{ width: 32, height: 32 }}>{claim.patient[0]}</Avatar>
                            <Box>
                              <Typography variant="body2">{claim.patient}</Typography>
                              <Typography variant="caption" color="text.secondary">
                                ID: {claim.id}
                              </Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>{claim.date}</TableCell>
                        <TableCell>
                          <StatusBadge status={claim.status as StatusType} />
                        </TableCell>
                        <TableCell>
                          <Chip
                            label="MedsCheck"
                            size="small"
                            sx={{
                              bgcolor: 'primary.light',
                              color: 'primary.main',
                              fontWeight: 500,
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          {claim.status === 'returned' ? (
                            <Typography variant="caption" color="error">
                              2 issues need attention
                            </Typography>
                          ) : (
                            <Typography variant="caption" color="text.secondary">
                              No issues
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button size="small" variant="outlined">
                              View
                            </Button>
                            <Button size="small" variant="contained" disabled={claim.status === 'approved'}>
                              {claim.status === 'pending' ? 'Submit' : 'Edit'}
                            </Button>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Card>
            </Box>
          )}

          {/* Add Analytics Tab Content */}
          {activeTab === 'analytics' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <Box sx={{ display: 'grid', gap: 2, gridTemplateColumns: { xs: '1fr', lg: '4fr 3fr' } }}>
                <Card>
                  <CardHeader title="Performance Metrics" />
                  <CardContent>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                      {performanceData.map((item) => (
                        <Box key={item.metric}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ flex: 1 }}>
                              <Typography variant="body2" fontWeight="medium">
                                {item.metric}
                              </Typography>
                              <Typography variant="h6" fontWeight="bold">
                                {item.unit}
                                {item.value.toLocaleString()}
                                <Typography component="span" variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                                  / {item.unit}
                                  {item.target.toLocaleString()}
                                </Typography>
                              </Typography>
                            </Box>
                            <Box sx={{ width: '50%', ml: 2 }}>
                              <LinearProgress
                                variant="determinate"
                                value={(item.value / item.target) * 100}
                                sx={{ height: 8, borderRadius: 1 }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      ))}
                    </Box>
                  </CardContent>
                </Card>

                <Card>
                  <CardHeader title="Revenue Trend" subheader="Monthly revenue from MedsChecks" />
                  <CardContent>
                    <Box sx={{ height: 300 }}>
                      <ReactApexChart options={lineChartOptions} series={lineChartSeries} type="line" height={300} />
                    </Box>
                  </CardContent>
                </Card>
              </Box>

              <Card>
                <CardHeader title="Detailed Analytics" />
                <CardContent>
                  <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
                    <Select defaultValue="thisMonth" sx={{ width: 180 }}>
                      <MenuItem value="thisWeek">This Week</MenuItem>
                      <MenuItem value="thisMonth">This Month</MenuItem>
                      <MenuItem value="lastMonth">Last Month</MenuItem>
                      <MenuItem value="lastQuarter">Last Quarter</MenuItem>
                      <MenuItem value="thisYear">This Year</MenuItem>
                    </Select>
                    <Button variant="outlined" startIcon={<Download />}>
                      Export Data
                    </Button>
                  </Box>

                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Completed MedsChecks</TableCell>
                        <TableCell>Revenue</TableCell>
                        <TableCell>Avg. Time to Claim</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {trendsData.map((item) => (
                        <TableRow key={item.month}>
                          <TableCell>{item.month}</TableCell>
                          <TableCell>{item.count}</TableCell>
                          <TableCell>${item.revenue.toLocaleString()}</TableCell>
                          <TableCell>{(Math.random() * (2 - 0.5) + 0.5).toFixed(1)} days</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Box>
          )}

          {/* Add Reports Tab Content */}
          {activeTab === 'reports' && (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
              <Card>
                <CardHeader title="Generate Reports" />
                <CardContent>
                  <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <Select defaultValue="monthlyClaims" sx={{ width: 250 }}>
                      <MenuItem value="monthlyClaims">Monthly Claims Summary</MenuItem>
                      <MenuItem value="quarterlyPerformance">Quarterly Performance Report</MenuItem>
                      <MenuItem value="annualRevenue">Annual Revenue Analysis</MenuItem>
                      <MenuItem value="patientSatisfaction">Patient Satisfaction Survey</MenuItem>
                      <MenuItem value="efficiencyMetrics">Efficiency Metrics Report</MenuItem>
                    </Select>
                    <Select defaultValue="pdf" sx={{ width: 120 }}>
                      <MenuItem value="pdf">PDF</MenuItem>
                      <MenuItem value="csv">CSV</MenuItem>
                    </Select>
                    <Button variant="contained">Generate Report</Button>
                  </Box>
                </CardContent>
              </Card>

              <Card>
                <CardHeader title="Recent Reports" />
                <CardContent>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Report Name</TableCell>
                        <TableCell>Date Generated</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {recentReportsData.map((report) => (
                        <TableRow key={report.id}>
                          <TableCell>{report.name}</TableCell>
                          <TableCell>{report.date}</TableCell>
                          <TableCell>{report.type}</TableCell>
                          <TableCell>
                            <Button startIcon={<FileText />} variant="text" size="small" sx={{ textTransform: 'none' }}>
                              Download
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Box>
          )}
        </motion.div>
      </AnimatePresence>
    </Box>
  );
}
