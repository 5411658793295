import { useContext, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import AuthContext from 'contexts/AuthContext';
import { APICore } from 'helpers/api/apiCore';
import { logger } from 'utils/logger';
import { useAuth } from './useAuth';
const api = new APICore();

const useSmsEndpoint = () => {
  const username = process.env.REACT_APP_API_USERNAME_AUTHORIZER;
  const password = process.env.REACT_APP_API_PASSWORD_AUTHORIZER;
  const { getSession } = useAuth();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    getSession().then((response) => {
      const token = response?.tokens?.idToken?.toString();
      setToken(token || null);
    });
  }, []);

  const axiosInstance = useMemo(() => {
    return axios.create({
      baseURL: process.env.REACT_APP_SMS_SERVICE_URL, // Replace with your base URL
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
  }, [token]);

  return axiosInstance;
};

export default useSmsEndpoint;
