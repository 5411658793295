import React, { useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './SupplyCalendar.css';
import CalendarEventComponent from './CalendarEventComponent';
import PatientPrescriptionModal from './PatientPrescriptionModal';
import { PatientDetails, DrugCalendar } from '../types/patient';

const localizer = momentLocalizer(moment);

export interface CalendarEventDetails {
  name: string;
  time: string;
  color: string;
  endDate: string;
  date: string;
  eventDetails?: {
    id: string;
    status: string;
    extendedText: string;
    drugName: string;
  };
}

export interface EventsMap {
  [date: string]: CalendarEventDetails[];
}

interface CalendarEvent {
  id: string;
  title: string;
  start: Date;
  end: Date;
  color?: string;
  isMultiDay?: boolean;
  hasSpecialEvent?: boolean;
  eventDetails?: {
    id: string;
    status: string;
    extendedText: string;
    drugName: string;
  };
}

interface EventProps {
  event: CalendarEvent;
  title: React.ReactNode;
}

interface SupplyCalendarProps {
  events: EventsMap;
  currentDate: Date;
  onNavigate: (newDate: Date) => void;
  specialDates: string[];
  patientDetails: PatientDetails | undefined;
  drugCalendars: Record<string, DrugCalendar>;
  onEventClick?: (event: CalendarEventDetails) => void;
  pharmacyID: string;
  pharmacyName: string;
  pharmacistID: string;
  pharmacistName: string;
}

const SupplyCalendar: React.FC<SupplyCalendarProps> = ({
  events = {},
  currentDate,
  onNavigate,
  specialDates = [],
  patientDetails,
  drugCalendars,
  onEventClick,
  pharmacyID,
  pharmacyName,
  pharmacistID,
  pharmacistName,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<CalendarEvent | null>(null);

  const calendarEvents: CalendarEvent[] = Object.entries(events).flatMap(([date, dayEvents]: [string, CalendarEventDetails[]]) =>
    dayEvents.map((event: CalendarEventDetails, index: number) => {
      const startTime = moment(`${date}T${event.time}`);
      const endTime = event.endDate
        ? moment(`${event.endDate}T${event.time}`).add(30, 'minutes')
        : startTime.clone().add(30, 'minutes');

      return {
        id: `${date}-${index}`,
        title: `${event.name}`,
        start: startTime.toDate(),
        end: endTime.toDate(),
        color: event.color,
        isMultiDay: !!event.endDate,
        hasSpecialEvent: specialDates.includes(date),
        eventDetails: event.eventDetails,
      };
    })
  );

  const eventStyleGetter = (event: CalendarEvent) => {
    return {
      style: {
        backgroundColor: event.color || '#3174ad',
        borderRadius: '4px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: 'block',
      },
    };
  };

  const handleEventClick = (event: CalendarEvent) => {
    if (onEventClick && event.eventDetails) {
      onEventClick({
        name: event.title,
        time: event.start.toTimeString().split(' ')[0],
        color: event.color || '',
        endDate: event.end.toISOString(),
        date: event.start.toLocaleDateString(),
        eventDetails: {
          id: event.eventDetails.id,
          status: event.eventDetails.status,
          extendedText: event.eventDetails.extendedText,
          drugName: event.eventDetails.drugName
        }
      });
    }
  };

  return (
    <div style={{ height: '800px' }}>
      <Calendar
        popup
        localizer={localizer}
        events={calendarEvents}
        startAccessor="start"
        endAccessor="end"
        style={{ height: '100%' }}
        eventPropGetter={eventStyleGetter}
        components={{
          event: (props: EventProps) => (
            <CalendarEventComponent {...props} hasSpecialEvent={props.event.hasSpecialEvent} />
          ),
        }}
        date={currentDate}
        onNavigate={onNavigate}
        views={['month', 'week', 'day', 'agenda']}
        step={30}
        timeslots={2}
        min={new Date(0, 0, 0, 0, 0, 0)}
        max={new Date(0, 0, 0, 23, 59, 59)}
        onSelectEvent={handleEventClick}
      />
      <PatientPrescriptionModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          setSelectedEvent(null);
        }}
        eventDate={selectedEvent?.start.toLocaleDateString()}
        patientDetails={patientDetails || undefined}
        drugDetails={
          selectedEvent?.eventDetails?.drugName ? drugCalendars[selectedEvent.eventDetails.drugName] : undefined
        }
        eventId={selectedEvent?.eventDetails?.id}
        calendarId={selectedEvent?.eventDetails?.drugName ? drugCalendars[selectedEvent.eventDetails.drugName]?.calendarID : undefined}
        pharmacyID={pharmacyID}
        pharmacyName={pharmacyName}
        pharmacistID={pharmacistID}
        pharmacistName={pharmacistName}
      />
    </div>
  );
};

export default SupplyCalendar;
