import React from 'react';
import { Box, Typography } from '@mui/material';
import { Check, Close } from '@mui/icons-material';

interface EventProps {
  event: {
    title: string;
    color?: string;
    eventDetails?: {
      status?: string;
      extendedText?: string;
    };
  };
  hasSpecialEvent?: boolean;
}

const CalendarEventComponent: React.FC<EventProps> = ({ event, hasSpecialEvent }) => {
  const status = event.eventDetails?.status;
  const isAdministered = status === 'ADMINISTERED';
  const isMissed = status === 'MISSED';

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: '100%',
        p: 0.5,
        position: 'relative',
      }}
    >
      <Typography variant="body2" noWrap>
        {event.title}
      </Typography>
      {(isAdministered || isMissed) && (
        <Box
          sx={{
            position: 'absolute',
            right: 4,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 16,
            height: 16,
            borderRadius: '50%',
            bgcolor: 'rgba(255, 255, 255, 0.9)',
          }}
        >
          {isAdministered ? (
            <Check
              sx={{
                fontSize: 14,
                color: event.color || 'primary.main',
              }}
            />
          ) : (
            <Close
              sx={{
                fontSize: 14,
                color: 'error.main',
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default CalendarEventComponent;
